import { Grid, IconButton } from '@hexa-ui/components';
import { Plus, Trash2 } from '@hexa-ui/icons';
import moment from 'moment';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Spinner } from 'supplier-portal-shared-components';
import { apiDrupal } from '../../../../../Api/Api';
import StyledAlert from '../../../../../components/Alert/Alert';
import StyledButton from '../../../../../components/Button/Button';
import FlexContainer from '../../../../../components/FlexContainer';
import StyledInput from '../../../../../components/Input/Input';
import StyledTable from '../../../../../components/Table/Table';
import { AlertContext } from '../../../../../contexts/alert.context';
import { DeleteModalContext } from '../../../../../contexts/deleteModal.context';
import useTimeWindowHandler from '../../../../../hook/useTimeWindowHandler';
import useWindowDimensions from '../../../../../hook/useWindowDimensions';
import useWindowScale from '../../../../../hook/useWindowScale';
import WindowFocusHandler from '../../../../../hook/windowFocusHandler';
import { useAppSelector } from '../../../../../store';
import { TimeWindowType } from '../../../../../store/dataTypes';
import { base_url } from '../../../../../utils/constants';
import ModalDelete from '../../../../../components/ModalDelete/ModalDelete';

const { createTimeWindow, updateTimeWindow } = useTimeWindowHandler();

type campaignDate = {
  start: {
    date: string;
    time: string;
  };
  end: {
    date: string;
    time: string;
  };
};

interface Error {
  description: string
  type: {
    first: boolean
    last: boolean
  }
}

function reducer(state, action) {
  switch (action.type) {
    case 'changed_field': {
      return {
        ...state,
        [action.field.id]: action.field.value,
      };
    }
  }
}

export default function TabTimeWindow() {
  const { deleteModalState, setDeleteModalState } = useContext(DeleteModalContext);
  const { addToast } = useContext(AlertContext);
  const isAugmentedScale = useWindowScale();
  const { width } = useWindowDimensions();
  const campaign = useAppSelector((state) => state.campaignDetails.newCampaign);
  const [campaignDate, setCampaignDate] = React.useState<campaignDate>(null);

  const defaultValues = {
    first_draw: {
      date: moment(campaign?.period?.start).format('YYYY-MM-DD'),
      time: moment(campaign?.period?.start).format('HH:mm'),
    },
    last_draw: {
      date: moment(campaign?.period?.end).format('YYYY-MM-DD'),
      time: moment(campaign?.period?.end).format('HH:mm'),
    },
  };

  const [state, dispatch] = useReducer(reducer, defaultValues);

  const [successMessage, setSuccessMessage] = React.useState<string | null>(null);
  const [prizes, setPrizes] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  const [timewindowData, setTimewindowData] = React.useState<TimeWindowType[]>();

  const [errorMessage, setErrorMessage] = React.useState<Error>({
    description: '',
    type: {
      first: false,
      last: false
    }
  });

  const { getAllTimeWindow, deleteTimeWindow } = useTimeWindowHandler();
  const [selectedTimeWindows, setSelectedTimeWindows] = useState<any>([]);

  const handleRowSelect = (rowSelectEvent) => {
    const itemId = (rowSelectEvent.data as TimeWindowType).id;

    setSelectedTimeWindows((prevSelectedTimeWindows) => {
      if(prevSelectedTimeWindows.includes(itemId)) {
        return prevSelectedTimeWindows.filter((id) => id !== itemId);
      } else {
        return [...prevSelectedTimeWindows, itemId];
      }
    })
  }

  const handleRemoveArray = (value) => {
    deleteTimeWindow(value, campaign.id).then(() => {
      setTimewindowData([]);
      setSelectedTimeWindows([]);
      handleSearchAll();
    });
  }

  WindowFocusHandler(() => {
    checkIfHasPrizes();
    handleSearchAll();
  });

  React.useEffect(() => {
    if (campaign) {
      checkIfHasPrizes();
      setCampaignDate({
        start: {
          date: moment(campaign?.period?.start).format('YYYY-MM-DD'),
          time: moment(campaign?.period?.start).format('HH:mm'),
        },
        end: {
          date: moment(campaign?.period?.end).format('YYYY-MM-DD'),
          time: moment(campaign?.period?.end).format('HH:mm'),
        },
      });
    }
  }, [campaign]);


  const handleChange = (e, id) => {
    dispatch({
      type: 'changed_field',
      field: {
        value: e,
        id: id
      }
    });
  }

  const checkIfHasPrizes = () => {
    setLoading(true);
    apiDrupal
      .get(`${base_url}/hub/v2/competition/${campaign?.id}/prizes`)
      .then((response) => {
        const dataOBJ = Object.values(response.data.data.prizes);
        setPrizes(dataOBJ);
      })
      .finally(() => setLoading(false));
  };

  const handleCreateTimeWindow = async () => {
    setLoading(true);
    const now = new Date(Date.now());

    const first_draw = state.first_draw;
    const last_draw = state.last_draw;
    if (!first_draw.date || !first_draw.time || !last_draw.date || !last_draw.time) {
      addToast({
        message: 'Please, fill in all fields to continue.',
        type: 'error',
      });
      return;
    }

    const first_draw_date = new Date(state.first_draw.date + ' ' + state.first_draw.time)
    const last_draw_date = new Date(state.last_draw.date + ' ' + state.last_draw.time)

    if (
      validateFirstDrawDates(first_draw_date, last_draw_date) &&
      validateLastDrawDates(first_draw_date, last_draw_date)
    ) {
      await createTimeWindow(
        {
          first_draw_date: state.first_draw.date + ' ' + state.first_draw.time + ':00',
          last_draw_date: state.last_draw.date + ' ' + state.last_draw.time + ':00',
        },
        campaign.id
      )
        .then(async (res) => {
          if (res?.data?.error) {
            addToast({
              message: 'All prizes already have time window registred.',
              type: 'error',
            });
          } else {
            setErrorMessage({
              description: '',
              type: {
                first: false,
                last: false
              }
            })
            setSuccessMessage('Time Window successfully generated.');
            addToast({
              message: 'Timewindow successfuly generated',
              type: 'success',
            });
            handleSearchAll();
          }
        })
        .catch(() => {
          addToast({
            message: 'An error occurred while generating the timewindow.',
            type: 'error',
          });
        }).finally(() => setLoading(false));

      return;
    }
    setErrorMessage(prevState =>
    ({
      ...prevState,
      description: 'The dates must be between the beginning and end of the campaign.'
    })
    );
    setLoading(false)
  };

  const handleSearchAll = async () => {
    setLoading(true);
    await apiDrupal
      .get(`${base_url}/hub/v2/competition/${campaign?.id}/time-window?page=0&page_size=50000`)
      .then((res) => {
        const data = InsertActions(res.data.data);
        setTimewindowData(data);
      })
      .finally(() => setLoading(false));
  };

  function InsertActions(data: TimeWindowType[], setState?: React.SetStateAction<any>) {
    let aux: TimeWindowType[] = [];

    const actions = (timewindow: TimeWindowType) => (
      <FlexContainer display="inline-flex" gap="1rem">
        <IconButton
          icon={Trash2}
          variant="inherit"
          onClick={() => {
            setDeleteModalState({
              ...deleteModalState,
              timewindow,
              campaign,
              type: 'timewindow',
              isOpen: true,
            });
          }}
        />
      </FlexContainer>
    );

    data.map((item: TimeWindowType, index: number) => {
      let auxitem = item;
      auxitem.actions = actions(item);
      aux.push(auxitem);
    });

    // for (let i = 0; i < data.length; i++) {
    //   let item: TimeWindowType = data[i];
    //   aux[i] = Object.assign({}, item, {
    //     actions: actions(item),
    //   });
    // }

    return aux;
  }

  const validateFirstDrawDates = (firstDate: Date, lastDate: Date) => {
    let campaignStartDate = new Date(campaignDate.start.date + ' ' + campaignDate.start.time);
    let campaignLastDate = new Date(campaignDate.end.date + ' ' + campaignDate.end.time);

    if ((!state.first_draw.date && state.first_draw.time) || (state.first_draw.date && !state.first_draw.time)) {
      addToast({
        message: 'The start date cannot have empty fields',
        type: 'error',
      });

      setErrorMessage(prevState => ({
        ...prevState,
        type: {
          ...prevState.type,
          first: true
        }
      }))

      return false;
    }

    if (firstDate < campaignStartDate) {
      addToast({
        message: 'The start date selected cannot be less than the campaign start date.',
        type: 'error',
      });

      setErrorMessage(prevState => ({
        ...prevState,
        type: {
          ...prevState.type,
          first: true
        }
      }))

      handleChange({ date: '', time: '' }, 'first_draw')
      return false;
    }

    if (firstDate >= lastDate || firstDate >= campaignLastDate) {
      addToast({
        message: 'The start date selected cannot be greater than or equal the campaign end date.',
        type: 'error',
      });

      setErrorMessage(prevState => ({
        ...prevState,
        type: {
          ...prevState.type,
          first: true
        }
      }))

      handleChange({ date: '', time: '' }, 'first_draw')
      return false;
    }

    setErrorMessage(prevState => ({
      ...prevState,
      type: {
        ...prevState.type,
        first: false
      }
    }))
    return true;
  };

  const validateLastDrawDates = (firstDate: Date, lastDate: Date) => {
    let campaignStartDate = new Date(campaignDate.start.date + ' ' + campaignDate.start.time);
    let campaignLastDate = new Date(campaignDate.end.date + ' ' + campaignDate.end.time);

    if ((!state.last_draw.date && state.last_draw.time) || (state.last_draw.date && !state.last_draw.time)) {
      addToast({
        message: 'The end date cannot have empty fields',
        type: 'error',
      });

      setErrorMessage(prevState => ({
        ...prevState,
        type: {
          ...prevState.type,
          last: true
        }
      }))

      return false;
    }

    if (lastDate <= campaignStartDate || lastDate <= firstDate) {
      addToast({
        message: 'The end date cannot be less than or equal to the start date.',
        type: 'error',
      });

      setErrorMessage(prevState => ({
        ...prevState,
        type: {
          ...prevState.type,
          last: true
        }
      }))

      handleChange({ date: '', time: '' }, 'last_draw')
      return false;
    }
    if (lastDate > campaignLastDate) {
      addToast({
        message: 'The end date selected cannot be greater than the campaign end date.',
        type: 'error',
      });

      setErrorMessage(prevState => ({
        ...prevState,
        type: {
          ...prevState.type,
          last: true
        }
      }))

      handleChange({ date: '', time: '' }, 'last_draw')
      return false;
    }

    setErrorMessage(prevState => ({
      ...prevState,
      type: {
        ...prevState.type,
        last: false
      }
    }))
    return true;
  };

  useEffect(() => {
    if (deleteModalState.isOpen === false) {
      handleSearchAll();
    }
  }, [deleteModalState.isOpen]);

  return (
    <FlexContainer display="flex" justifyContent="space-evenly" flexDirection="column" gap="1rem">
      {prizes.length > 0 ? (
        <>
          <FlexContainer display="flex" justifyContent="space-between" alignItems="flex-end">
            <FlexContainer display="flex" minWidth="60%" width='100%' flexDirection="row">
              <Grid.Container
                type="fluid"
                style={{
                  justifyContent: 'center',
                  // display: width <= 1024 ? 'contents' : '',
                  padding: '0px',
                  margin: '0px',
                  flex: 1,
                }}
              >
                <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} >
                  {campaignDate && (
                    <Grid.Container
                      type="fluid"
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-end',
                        margin: 0,
                        width: '100%',
                        alignItems: 'flex-end',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          height: 'max-content',
                          gap: '1rem',
                          flexDirection: 'row',
                          alignItems: 'flex-end',
                          marginBottom: '0.5rem',
                          width: '100%',
                          flexWrap: 'wrap',
                        }}
                      >
                        <StyledInput
                          required
                          size="large"
                          value={state.first_draw.date}
                          defaultValue={state.first_draw.date}
                          onChange={e => handleChange({ ...state.first_draw, date: e.currentTarget.value }, 'first_draw')}
                          label="First Draw Date"
                          hint="When the first reward will be drawn"
                          placeholder="Insert the date"
                          type="date"
                          hasError={errorMessage.type.first}
                        />
                        <StyledInput
                          required
                          size="large"
                          value={state.first_draw.time}
                          defaultValue={state.first_draw.time}
                          placeholder="Insert the date"
                          onChange={e => handleChange({ ...state.first_draw, time: e.currentTarget.value }, 'first_draw')}
                          type="time"
                          hasError={errorMessage.type.first}
                        />
                        <StyledInput
                          required
                          size="large"
                          value={state.last_draw.date}
                          defaultValue={state.last_draw.date}
                          type="date"
                          label="Last draw date"
                          hint="When the first reward will be drawn"
                          placeholder="Insert the date"
                          hasError={errorMessage.type.last}
                          onChange={e => handleChange({ ...state.last_draw, date: e.currentTarget.value }, 'last_draw')}
                        />
                        <StyledInput
                          required
                          value={state.last_draw.time}
                          defaultValue={state.last_draw.time}
                          size="large"
                          placeholder="Insert the date"
                          type="time"
                          hasError={errorMessage.type.last}
                          onChange={e => handleChange({ ...state.last_draw, time: e.currentTarget.value }, 'last_draw')}
                        />
                      </div>
                      <StyledButton leading icon={Plus}
                        disabled={loading}
                        onClick={handleCreateTimeWindow} css={{ marginLeft: 'auto', marginBottom: '0.5rem', marginTop: '0.5rem' }}>
                        Generate time window
                      </StyledButton>
                    </Grid.Container>
                  )}
                </Grid.Item>
              </Grid.Container>
            </FlexContainer>
          </FlexContainer>
          {errorMessage.type.first || errorMessage.type.last ? <StyledAlert type='error' message='The dates must be between the beginning and end of the campaign.' /> : null}
          <StyledAlert
            message="If you wish to have custom time windows, add only one prize on the previous step and add the remaining prizes in the Campaign’s details page afterwards."
            type="warning"
            css={{
              width: '100%',
            }}
          />
          {selectedTimeWindows.length > 0 ?
            <div style={{ position: 'relative', width: '40px', ...(selectedTimeWindows.length < 10 ? { left: '220px' } : selectedTimeWindows.length >= 100 ? { left: '240px' } : { left: '230px' }) }}>
              <ModalDelete
                height='8rem'
                handleDelete={() => handleRemoveArray(selectedTimeWindows)}
                trigger={<IconButton icon={Trash2} variant="inherit" style={{ filter: 'invert(54%) sepia(89%) saturate(5215%) hue-rotate(341deg) brightness(98%) contrast(81%)' }}/>}
                title="Delete time windows?"
                description={
                  <>
                    Do you really wish to delete these time windows?
                    <br />
                    This action can’t be undone, and you will need to manually restock the prizes that
                    have already been distributed.
                  </>
                }
          />
            </div> : null}
          <StyledTable
            maxWidth={width < 1200 ? '' : isAugmentedScale ? '58.3vw' : ''}
            loading={loading}
            data={timewindowData || []}
            className={'deleteAllWizard'}
            selectable={{
              useCheckbox: true,
              onRowSelect: (rowSelectEvent) => {
                handleRowSelect(rowSelectEvent);
              },
              onAllPageRowsSelect : (rowsSelectEvent) => {
                setSelectedTimeWindows((rowsSelectEvent.data as any[]).map((item) => item.original.id))
              },
              onDeselectAll: () => {
                setSelectedTimeWindows([]);
              }
              }}
            columns={[
              {
                Header: 'ID',
                accessor: 'id',
                disableSortBy: false,
                style: {
                  width: '12%',
                },
              },
              {
                Header: 'Prize',
                accessor: 'name',
                disableSortBy: false,
                style: {
                  width: '57%',
                },
              },
              {
                Header: 'Draw date',
                accessor: 'draw_date',
                disableSortBy: false,
                style: {
                  width: '26%',
                },
              },
              // {
              //   Header: 'Author',
              //   accessor: 'authored_by',
              //   disableSortBy: false,
              //   style: {
              //     width: '25%',
              //   },
              // },
              {
                Header: 'Actions',
                accessor: 'actions',
                disableSortBy: true,
                style: {
                  width: '5%',
                },
              },
            ]}
          />
        </>
      ) : !loading ? (
        <StyledAlert
          style={{ width: '100%' }}
          message="You do not have any prizes registered yet."
          type="warning"
        />
      ) : (
        <Spinner color={'inherit'} show dataTest="transition-spinner" />
      )}
    </FlexContainer>
  );
}
