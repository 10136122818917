import { Card, Grid, Heading, Select, ExpandableSection, Quantifier, Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import React, { useContext, useState } from 'react';
import useWindowDimensions from '../../hook/useWindowDimensions';
import StyledInput from '../../components/Input/Input';
import SearchDropdown from '../../components/SearchDropdown/SearchDropdown';
import StyledFileUploader from '../../components/FileUploader/FileUploader';
import { base_url } from '../../utils/constants';
import StyledButton from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import verifyImgType from '../../utils/verifyImgType';
import { AlertContext } from '../../contexts/alert.context';
import StyledTextarea from '../../components/Textarea/Textarea';
import StyledSelect from '../../components/Select/Select';
import FlexContainer from '../../components/FlexContainer';
import QuantifierLabeled from '../../components/QuantifierLabeled/QuantifierLabeled';

const StyledPadding = styled('div', {
  paddingBottom: '1rem',
});

const AddQuestion: React.FC = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const { addToast } = useContext(AlertContext);
  const [fileError, setFileError] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [showFeedBack, setShowFeedback] = useState(false);
  const [showAnswers, setShowAnswers] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [showResult, setShowResult] = useState(false);

  const TypeItems = [{ name: '1st option' }, { name: 'Multiple choice' }];
  const items = [{name: 'Add Quiz: Simple'}]
  const Personality = [{name: 'Personality'}]

  const typelist = ['png', 'jpg', 'jpeg', 'gif'];

  const search = (searchTerm) => {
    const filteredItems = TypeItems.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return Promise.resolve(filteredItems);
  };

  const sizeValidation = (file) => {
    if (file[0].file.size > 1000 * 1000) {
      addToast({ message: 'The selected file size exceeds the allowed limit.', type: 'error' });
      return true;
    }
    return false;
  };

  const verifiedFile = (e) => {
    if (!verifyImgType(typelist, e)) {
      setFileError(true);
      return;
    }
    if (!sizeValidation(e)) {
      setFileError(false);
    }
  };

  return (
    <>
      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Heading size="H2" css={{ marginBottom: '0.5rem' }}>
          Add question
        </Heading>
      </Grid.Item>

      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Card elevated="medium" border="medium" css={{ width: '100%' }}>
          <Grid.Container
            type="fluid"
            style={{
              justifyContent: 'flex-start',
              display: width <= 1024 ? 'contents' : '',
              padding: '1.5rem 0 1rem 0',
            }}
          >
            <Grid.Item xl={6} lg={6} md={6} sm={12} xs={12} style={{ paddingLeft: '0px' }}>
              <Grid.Container
                type="fluid"
                style={{
                  width: '100%',
                  height: 'max-content',
                  margin: 0,
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <StyledPadding>
                  <SearchDropdown
                    width=""
                    label="Quiz*"
                    hint="&nbsp;"
                    value='Quiz'
                    disabled={true}
                    placeholder=""
                    onSearch={search}
                    onChange={(e) => {
                      e.currentTarget.value;
                    }}
                    onClickResult={(item) => {
                      console.log(`Evento selecionado: ${item.name}`);
                    }}
                  />
                </StyledPadding>
                <StyledPadding>
                  <StyledSelect
                    error={''}
                    value={TypeItems[0].name}
                    size="large"
                    label="Type*"
                    width={'30%'}
                    onChange={(value) => {}}
                  >
                    {TypeItems &&
                      TypeItems.map((item, index: number) => {
                        return (
                          <Select.Option key={item.name + index} value={String(item.name)}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                  </StyledSelect>
                </StyledPadding>
                {showQuestion === false ?
                    <StyledPadding>
                      <StyledSelect
                        error={''}
                        value={items[0].name}
                        size="large"
                        label="Question*"
                        width={'100%'}
                        onChange={(value) => { setShowQuestion(!showQuestion) }}
                      >
                        {items &&
                          items.map((item, index: number) => {
                            return (
                              <Select.Option key={item.name + index} value={String(item.name)}>
                                {item.name}
                              </Select.Option>
                            );
                          })}
                      </StyledSelect>
                    </StyledPadding>
                    :
                    <>
                    <StyledPadding>
                      <Heading size='H4'>
                        Quiz: Simple
                      </Heading>
                    </StyledPadding>
                    <StyledPadding>
                      <StyledInput
                        size="large"
                        label="Title*"
                        width={''}
                        placeholder=""
                      />
                    </StyledPadding>
                    <StyledPadding>
                      <StyledTextarea
                        label="Description"
                        hint="&nbsp;"
                        placeholder="Insert your text here"
                        value={''}
                        width={'100%'}
                        maxLength={150}
                        characterCounter
                        style={{
                          height: '90px',
                          resize: 'none',
                        }}
                        onChange={(e) => (e.currentTarget.value)}
                      />
                    </StyledPadding>
                    <StyledPadding>
                      <StyledFileUploader
                        title="Image"
                        url={base_url + ''}
                        accept=".png,.jpg,.jpeg,.gif"
                        maxFileSize={1000000}
                        message="One file only. Allowed PNG, JPG, JPEG, GIF format and must be less than 256MB"
                        width="30vw"
                        onDrop={(e) => {
                          verifiedFile(e);
                        }}
                      />
                    </StyledPadding>
                    <StyledPadding>
                      <StyledInput
                        size="large"
                        label="Alternative text*"
                        width="30vw"
                        hint="Short description of the image used by screen readers and displayed when the image is not loaded. This is important for accessibility."
                        placeholder=""
                      />
                    </StyledPadding>
                    <div
                      style={{
                        width: '100%',
                        display: 'inline-flex',
                        gap: '0.5rem',
                        paddingBottom: '20px',
                      }}
                    >
                      <StyledButton isLoading={loading} variant="primary" onClick={() => null}>
                        Save
                      </StyledButton>
                      <StyledButton variant="secondary" isLoading={loading} onClick={() => setShowQuestion(!showQuestion)}>
                        Cancel
                      </StyledButton>
                    </div>
                  </>
                }
                {showFeedBack == false ?
                <StyledPadding>
                  <StyledSelect
                    error={''}
                    value={items[0].name}
                    size="large"
                    label="Feedback*"
                    width={'100%'}
                    onChange={(value) => { setShowFeedback(!showFeedBack) }}
                  >
                    {items &&
                      items.map((item, index: number) => {
                        return (
                          <Select.Option key={item.name + index} value={String(item.name)}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                  </StyledSelect>
                </StyledPadding>
                :
                  <>
                    <StyledPadding>
                      <Heading size='H4'>
                        Quiz: Simple
                      </Heading>
                    </StyledPadding>
                    <StyledPadding>
                      <StyledInput
                        size="large"
                        label="Title*"
                        width={''}
                        placeholder=""
                      />
                    </StyledPadding>
                    <StyledPadding>
                      <StyledTextarea
                        label="Description"
                        hint="&nbsp;"
                        placeholder="Insert your text here"
                        value={''}
                        width={'100%'}
                        maxLength={150}
                        characterCounter
                        style={{
                          height: '90px',
                          resize: 'none',
                        }}
                        onChange={(e) => (e.currentTarget.value)}
                      />
                    </StyledPadding>
                    <StyledPadding>
                      <StyledFileUploader
                        title="Image"
                        url={base_url + ''}
                        accept=".png,.jpg,.jpeg,.gif"
                        maxFileSize={1000000}
                        message="One file only. Allowed PNG, JPG, JPEG, GIF format and must be less than 256MB"
                        width="30vw"
                        onDrop={(e) => {
                          verifiedFile(e);
                        }}
                      />
                    </StyledPadding>
                    <StyledPadding>
                      <StyledInput
                        size="large"
                        label="Alternative text*"
                        width="30vw"
                        hint="Short description of the image used by screen readers and displayed when the image is not loaded. This is important for accessibility."
                        placeholder=""
                      />
                    </StyledPadding>
                    <div
                      style={{
                        width: '100%',
                        display: 'inline-flex',
                        gap: '0.5rem',
                        paddingBottom: '20px',
                      }}
                    >
                      <StyledButton isLoading={loading} variant="primary" onClick={() => null}>
                        Save
                      </StyledButton>
                      <StyledButton variant="secondary" isLoading={loading} onClick={() => setShowFeedback(!showFeedBack)}>
                        Cancel
                      </StyledButton>
                    </div>
                  </>
                }
                <StyledPadding>
                  <ExpandableSection headerText={'Answers*'}>
                    {showAnswers == false ?
                    <FlexContainer display='flex' gap='1rem' alignItems='center'>
                      <StyledSelect
                        error={''}
                        value={Personality[0].name}
                        size="large"
                        width={'25%'}
                        onChange={(value) => { }}
                      >
                        {Personality &&
                          Personality.map((item, index: number) => {
                            return (
                              <Select.Option key={item.name + index} value={String(item.name)}>
                                {item.name}
                              </Select.Option>
                            );
                          })}
                      </StyledSelect>
                      <StyledButton variant="secondary" onClick={() => setShowAnswers(!showAnswers)}>
                        Add new answer
                      </StyledButton>
                    </FlexContainer>
                    :
                    <>
                    {showAnswer == false ?
                      <StyledPadding>
                        <StyledSelect
                          error={''}
                          value={items[0].name}
                          size="large"
                          label="Answer*"
                          width={'100%'}
                          onChange={(value) => { setShowAnswer(!showAnswer) }}
                        >
                          {items &&
                            items.map((item, index: number) => {
                              return (
                                <Select.Option key={item.name + index} value={String(item.name)}>
                                  {item.name}
                                </Select.Option>
                              );
                            })}
                        </StyledSelect>
                      </StyledPadding>
                      :
                      <>
                        <StyledPadding>
                          <Heading size='H4'>
                            Quiz: Simple
                          </Heading>
                        </StyledPadding>
                        <StyledPadding>
                          <StyledInput
                            size="large"
                            label="Title*"
                            width={''}
                            placeholder=""
                          />
                        </StyledPadding>
                        <StyledPadding>
                          <StyledTextarea
                            label="Description"
                            hint="&nbsp;"
                            placeholder="Insert your text here"
                            value={''}
                            width={'100%'}
                            maxLength={150}
                            characterCounter
                            style={{
                              height: '90px',
                              resize: 'none',
                            }}
                            onChange={(e) => (e.currentTarget.value)}
                          />
                        </StyledPadding>
                        <StyledPadding>
                          <StyledFileUploader
                            title="Image"
                            url={base_url + ''}
                            accept=".png,.jpg,.jpeg,.gif"
                            maxFileSize={1000000}
                            message="One file only. Allowed PNG, JPG, JPEG, GIF format and must be less than 256MB"
                            width="30vw"
                            onDrop={(e) => {
                              verifiedFile(e);
                            }}
                          />
                        </StyledPadding>
                        <StyledPadding>
                          <StyledInput
                            size="large"
                            label="Alternative text*"
                            width="30vw"
                            hint="Short description of the image used by screen readers and displayed when the image is not loaded. This is important for accessibility."
                            placeholder=""
                          />
                        </StyledPadding>
                        <div
                          style={{
                            width: '100%',
                            display: 'inline-flex',
                            gap: '0.5rem',
                            paddingBottom: '20px',
                          }}
                        >
                          <StyledButton isLoading={loading} variant="primary" onClick={() => null}>
                            Save
                          </StyledButton>
                          <StyledButton variant="secondary" isLoading={loading} onClick={() => setShowAnswer(!showAnswer)}>
                            Cancel
                          </StyledButton>
                        </div>
                      </>
                      }
                      {showResult && (
                        <>
                          <StyledPadding>
                            <SearchDropdown
                              width=""
                              label="Result*"
                              placeholder=""
                              onSearch={search}
                              onChange={(e) => {
                                e.currentTarget.value;
                              }}
                              onClickResult={(item) => {
                                console.log(`Evento selecionado: ${item.name}`);
                              }}
                            />
                          </StyledPadding>
                          <StyledPadding>
                              <div style={{ width: '35%' }}>
                                <QuantifierLabeled
                                  title="Points*"
                                  defaultValue={0}
                                  value={0}
                                  min={1}
                                  onChange={(e) => {
                                    let v = parseInt(e.currentTarget.value, 10);
                                    if (isNaN(v)) {
                                      v = 1;
                                    }
                                    v = v <= 0 ? 1 : v;
                                    /* changeStateForm(setForm, form, 'player_progress_expiration_time', v); */
                                  }}
                                  onClickPlusButton={() => {
                                    /* changeStateForm(setForm, form, 'player_progress_expiration_time', form.player_progress_expiration_time + 1) */
                                  }}
                                  onClickMinusButton={() => {
                                    /* changeStateForm(setForm, form, 'player_progress_expiration_time', form.player_progress_expiration_time - 1) */
                                  }}
                                />
                              </div>
                          </StyledPadding>
                        </>
                      )}
                      <StyledPadding>
                        {!showResult &&
                        <Paragraph weight='semibold' size='small'>
                          Results*
                        </Paragraph>
                        }
                        <StyledButton variant="secondary" size='medium' onClick={() => setShowResult(!showResult) }>
                          Add Quiz Answer: Personality
                        </StyledButton>
                      </StyledPadding>
                    <div
                      style={{
                        width: '100%',
                        display: 'inline-flex',
                        gap: '0.5rem',
                        paddingBottom: '20px',
                      }}
                    >
                      <StyledButton isLoading={loading} variant="primary" onClick={() => null}>
                        Create answer
                      </StyledButton>
                      <StyledButton variant="secondary" isLoading={loading} onClick={() => (setShowAnswers(!showAnswers), setShowResult(false), setShowAnswer(false))}>
                        Cancel
                      </StyledButton>
                    </div>
                    </>
                    }
                  </ExpandableSection>
                </StyledPadding>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
          <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
            <div
              style={{
                width: '100%',
                display: 'inline-flex',
                justifyContent: 'flex-end',
                gap: '0.5rem',
                padding: '20px',
              }}
            >
              <StyledButton variant="secondary" isLoading={loading} onClick={() => navigate(-1)}>
                Cancel
              </StyledButton>
              <StyledButton isLoading={loading} variant="primary" onClick={() => null}>
                Save
              </StyledButton>
            </div>
          </Grid.Item>
        </Card>
      </Grid.Item>
    </>
  );
};

export default AddQuestion;
