import { Card } from '@hexa-ui/components';
import { Plus } from '@hexa-ui/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StyledButton from '../../components/Button/Button';
import StyledFilter from '../../components/Filter/Filter';
import FlexContainer from '../../components/FlexContainer';
import StyledTable from '../../components/Table/Table';
import handleFilter from '../campaignDetails/extra/filter';
import QuizHeaders from '../json/columnHeaders/QuizHeaders.json';
import mockData from '../json/mock/mockingQuizData.json';
import PageTitle from '../../components/PageTitle/PageTitle';

const Quiz: React.FC = () => {
  const navigate = useNavigate();

  const [data, setData] = useState(mockData);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState(mockData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setData(filteredData);
  }, [filteredData]);

  const handleSearch = (e) => {
    setSearch(e.currentTarget.value);
    const currentData = mockData;
    if (e.currentTarget.value) {
      const searchKeys = ['id', 'title', 'brand', 'published', 'actions'];
      handleFilter(e.currentTarget.value, currentData, searchKeys, setFilteredData);
    } else {
      setFilteredData(currentData);
    }
  };

  return (
    <>
      <PageTitle marginBottom="1rem" size="H2" title="Quiz" />
      <Card elevated={'medium'} border={'large'} >
        <FlexContainer
          width="100%"
          display={'inline-flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          padding={'1rem'}
          gap={'0.5rem'}
          flexWrap="wrap"
        >
          <div style={{ maxWidth: '496px', width: '496px' }}>
            <StyledFilter
              placeholder="Start typing to filter results"
              value={search}
              onChange={(e) => handleSearch(e)}
              onClear={(e) => handleSearch(e)}
            />
          </div>
          <StyledButton
            variant="primary"
            icon={Plus}
            leading
            onClick={() => navigate(`/bees-games/quiz/add-quiz`)}
          >
            Add Quiz
          </StyledButton>
        </FlexContainer>
        <StyledTable
          columns={QuizHeaders}
          search={false}
          data={data || []}
          onRow={(data) => ({
            onClick: (event: React.MouseEvent<HTMLTableRowElement>) => {
              navigate('/bees-games/quiz/quiz-details/' + data.id, {
                state: {
                  data: data
                },
              });
            },
          })}
          loading={loading}
          pagination={{
            pageSize: 10,
            pageSizeOptions: [5, 10, 20],
            showPageSizeSelector: true,
          }}
        />
      </Card>
    </>
  );
};

export default Quiz;
